import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import samples from "./samples.js";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { apiServices } from "../../service/api.service.js";
import swal from "sweetalert";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Assistant name is required"),
  welcomeMsg: Yup.string().required("Welcome message is required"),
  systemPrompt: Yup.string().required("System prompt is required"),
});

function CreateAgent({ setStep, setAgent }) {
  const [templateData, setTemplateData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [agentName, setAgentName] = useState("");

  function handleTemplate(name, setFieldValue) {
    setActiveTab(name);
    let sampleAgent = samples.find((a) => a.type === name);
    setAgentName(sampleAgent.name);
    setTemplateData(sampleAgent);
    setFieldValue("name", sampleAgent.name);
    setFieldValue("welcomeMsg", sampleAgent.welcomeMsg || "");
    setFieldValue("systemPrompt", sampleAgent.systemPrompt || "")
  }

  return (
    <section className="contact-us-section" id="contact-us-section">
      <Container>
        <h2 className="main-heading text-center shimmer">
          Kindly fill the details below <br /> to get started.
        </h2>
        <Row className="justify-content-center mt-3">
          <Col lg={8} md={10}>
            <div className="get-started-details">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: agentName || "",
                  welcomeMsg: templateData.welcomeMsg || "",
                  systemPrompt: templateData.systemPrompt || "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  console.log(values)
                  try {
                    let created = await apiServices.createAgent(values)
                    setAgent(created.data.agent)
                    setStep(step => step + 1)
                  } catch (error) {
                    swal({ icon: "warning", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message })
                  }
                }}
              >
                {({
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="formAssistantName">
                      <Form.Label>
                        Assistant Name (This can be adjusted at any time after creation.)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          setAgentName(e.target.value);
                        }}
                        isInvalid={touched.name && !!errors.name}
                        placeholder="New Assistant"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Welcome Message */}
                    <Form.Group className="mt-3">
                      <Form.Label>
                        Welcome Message
                        <OverlayTrigger
                          delay={{ show: 100, hide: 400 }}
                          overlay={
                            <Tooltip>
                              The first message that the assistant will say.
                            </Tooltip>
                          }
                        >
                          <svg
                            className="mx-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                          </svg>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        name="welcomeMsg"
                        placeholder="The first message that the assistant will say."
                        value={values.welcomeMsg}
                        onChange={handleChange}
                        isInvalid={touched.welcomeMsg && !!errors.welcomeMsg}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.welcomeMsg}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* System Prompt */}
                    <Form.Group className="mt-3">
                      <Form.Label>
                        System Prompt
                        <OverlayTrigger
                          delay={{ show: 100, hide: 400 }}
                          overlay={
                            <Tooltip>
                              The system prompt can be used to configure the
                              context, role, personality, instructions, and so
                              on for the assistant.
                            </Tooltip>
                          }
                        >
                          <svg
                            className="mx-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                          </svg>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="systemPrompt"
                        placeholder="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
                        value={values.systemPrompt}
                        onChange={handleChange}
                        isInvalid={touched.systemPrompt && !!errors.systemPrompt}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.systemPrompt}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Template Selector */}
                    <Row className="mt-3">
                      <Col sm={12}>
                        <div className="template-description">
                          <h5>Quickstart</h5>
                          <div className="quickstart-templates">
                            <Row>
                              <Col
                                md={6}
                                onClick={() => handleTemplate('appointment', setFieldValue)}
                                className="mb-3"
                              >
                                <div
                                  className={`quickstart-card ${activeTab === "appointment" ? "active" : ""
                                    }`}
                                >
                                  <h6>Appointment Setter</h6>
                                  <p>
                                    Designed for dental practices to demonstrate
                                    setting appointments.
                                  </p>
                                </div>
                              </Col>
                              <Col
                                md={6}
                                onClick={() => handleTemplate('customer', setFieldValue)}
                                className="mb-3"
                              >
                                <div
                                  className={`quickstart-card ${activeTab === "customer" ? "active" : ""
                                    }`}
                                >
                                  <h6>Customer Support</h6>
                                  <p>
                                    A versatile template designed with emotional
                                    intelligence and technical knowledge.
                                  </p>
                                </div>
                              </Col>
                              <Col md={6} onClick={() => handleTemplate('qa', setFieldValue)} className="mb-3">
                                <div className={`quickstart-card ${activeTab == 'qa' ? 'active' : ''}`}>
                                  <h6>Inbound Q/A</h6>
                                  <p>An inbound call agent example designed for comprehensive support.</p>
                                </div>
                              </Col>
                              <Col md={6} onClick={() => handleTemplate('game', setFieldValue)} className="mb-3">
                                <div className={`quickstart-card ${activeTab == 'game' ? 'active' : ''}`}>
                                  <h6>Game NPC</h6>
                                  <p>An assistant for demonstrating an in-game NPC.</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="text-center mt-3">
                      <Button disabled={isSubmitting} type="submit">Submit</Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CreateAgent;
