import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import { apiServices } from "../../service/api.service";
import swal from "sweetalert";

function PhoneVoice({ setStep, createdPhone }) {

  const [selectedValue, setSelectedValue] = useState("");
  const data = [
    { name: "Asteria", accent: "English (US)", gender: "Female", voice: "asteria_docs_venw0r.wav", value: "aura-asteria-en" },
    { name: "Luna", accent: "English (US)", gender: "Female", voice: "luna_docs_clom0e.wav", value: "aura-luna-en" },
    { name: "Stella", accent: "English (US)", gender: "Female", voice: "stella_docs_xh5jbv.wav", value: "aura-stella-en" },
    { name: "Athena", accent: "English (UK)", gender: "Female", voice: "athena_docs_wyznud.wav", value: "aura-athena-en" },
    { name: "Hera", accent: "English (US)", gender: "Female", voice: "hera_docs_xjkt4x.wav", value: "aura-hera-en" },
    { name: "Orion", accent: "English (US)", gender: "Male", voice: "orion_docs_aljv1q.mp3", value: "aura-orion-en" },
    { name: "Arcas", accent: "English (US)", gender: "Male", voice: "arcas_docs_pc9hxp.mp3", value: "aura-arcas-en" },
    { name: "Perseus", accent: "English (US)", gender: "Male", voice: "perseus_docs_ap7fc0.wav", value: "aura-perseus-en" },
    { name: "Angus", accent: "English (Ireland)", gender: "Male", voice: "angus_docs_lgse2b.wav", value: "aura-angus-en" },
    { name: "Orpheus", accent: "English (US)", gender: "Male", voice: "orpheus_docs_zdlpcf.wav", value: "aura-orpheus-en" },
    { name: "Helios", accent: "English (UK)", gender: "Male", voice: "helios_docs_ycjwoo.wav", value: "aura-helios-en" },
    { name: "Zeus", accent: "English (US)", gender: "Male", voice: "zeus_docs_fupdiv.wav", value: "aura-zeus-en" },
  ];

  const handleSelection = (val) => {
    setSelectedValue(val);
  };

  async function submitPhoneSetting() {
    if (!selectedValue) {
      swal({ icon: "warning", text: "Please select a voice for the phone number." })
      return;
    }
    try {
      await apiServices.updatePhoneNumber({ "id": createdPhone._id, "voice": selectedValue })
      setStep(s => s + 1)
    } catch (err) {
      swal({ icon: "warning", text: err?.response?.data?.message ? err?.response?.data?.message : err?.message })
    }
  }
  return <>
    <section className="contact-us-section" id="contact-us-section">
      <Container>
        <h2 className="main-heading text-center shimmer">
          Kindly fill the details below <br /> to get started.
        </h2>
        <Row className="justify-content-center mt-3">
          <Col lg={8} md={10}>
            <div className="get-started-details">
              <h5>Voice Configuration</h5>
              <h6>Choose from the list of voices for your AI agent.</h6>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Accent</th>
                    <th>Gender</th>
                    <th>Listen</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex">
                          <Form.Check
                            type="radio"
                            name="voice-selection"
                            checked={selectedValue == item.value}
                            onChange={() => handleSelection(item.value)}
                            className="me-2"
                          />
                          {item.name}
                        </div>
                      </td>
                      <td>{item.accent}</td>
                      <td>{item.gender}</td>
                      <td>
                        <audio style={{ height: "35px" }} id={item.voice} controls autoPlay={false}>
                          <source src={`https://dashboard.aioffice.pro/voices/${item.voice}`} type="audio/wav"></source>
                        </audio>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-center">
                <Button onClick={() => submitPhoneSetting()}>Submit</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </>
}

export default PhoneVoice