import { useState } from "react";
import { Loader } from "../../common/Loader";
import SignUpStep from "./SignUpStep";
import CreateAgent from "./CreateAgent";
import Phone from "./Phone";
import PhoneVoice from "./PhoneVoice";
import { Col, Container, Row } from "react-bootstrap";

function SignUp() {
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [templateData, setTemplateData] = useState({ type: "blank", welcomeMsg: "", systemPrompt: "" })
  const [createdPhone, setCreatedPhone] = useState({})
  const [user, setUser] = useState({})
  const [agent, setAgent] = useState({})

  const progress = (step / 4) * 100;

  return (
    <>
      {loader && <Loader />}
      <div>
        {
          step === 0 ?
            <SignUpStep setStep={setStep} setUser={setUser} />
            :
            step === 1
              ?
              <CreateAgent setStep={setStep} setAgent={setAgent} />
              :
              step === 2
                ?
                <Phone setStep={setStep} agent={agent} setCreatedPhone={setCreatedPhone} />
                :
                step === 3
                  ?
                  <PhoneVoice setStep={setStep} createdPhone={createdPhone} />
                  :
                  <section className="contact-us-section" id="contact-us-section">
                    <Container>
                      <Row className="justify-content-center mt-3">
                        <Col lg={7} md={10}>
                          <div className="sucessfully-signed-outer">
                            <div className="successfully-signed">
                              <h3 className="main-heading text-center shimmer">Signed up successfully.</h3>
                              <h4 className="text-center"><a href="https://dashboard.aioffice.pro/" target="_blank">Click here</a> to login.</h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </section>
        }
      </div>
    </>
  )
}

export default SignUp