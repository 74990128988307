import { createContext } from "react";
import swal from "sweetalert";

 export const apiUrl = "https://api.aioffice.pro"
//  export const apiUrl = "http://127.0.0.1:8000"


export const MyContext = createContext();

export const length_unit = "Ft"

export const Unauthorized=()=>{
    swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
})
}
