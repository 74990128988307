import { useEffect, useState } from "react"
import { apiServices } from "../../service/api.service"
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import swal from "sweetalert";

function Phone({ setStep, agent, setCreatedPhone }) {
  const [numbers, setNumbers] = useState([])
  const [selectedNumber, setSelectedNumber] = useState("")

  useEffect(() => {
    getAvailablePhoneNumbers()
  }, [])

  async function getAvailablePhoneNumbers() {
    try {
      let response = await apiServices.getAvailablePhoneNumbers()
      setNumbers(response.data.data)
    } catch (error) {
      swal({ icon: "warning", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message })
    }
  }

  async function buyPhone() {
    if (!selectedNumber) {
      swal({ icon: 'warning', text: 'Please select a phone number.' })
      return;
    }
    try {
      let created = await apiServices.buyPhoneNumber({ phone: selectedNumber, agent: agent._id })
      setCreatedPhone(created.data.data)
      setStep(step => step + 1)
    } catch (error) {
      swal({ icon: "warning", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message })
    }
  }


  return (
    <>
      <section className="contact-us-section" id="contact-us-section">
        <Container>
          <h2 className="main-heading text-center shimmer">
            Kindly fill the details below <br /> to get started.
          </h2>
          <Row className="justify-content-center mt-3">
            <Col lg={8} md={10}>
              <div className="get-started-details">
                {
                  numbers.length ?
                    <>
                      <p>Select a number to buy to use with AI Office agent.</p>
                      <Table>
                        <thead>
                          <tr>
                            <th>Phone Number</th>
                            <th>Price (Monthly fee)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            numbers.map(n => (
                              <tr onClick={() => setSelectedNumber(n.phone)}>
                                <td>
                                  <div className="d-flex">
                                    <Form.Check
                                      checked={selectedNumber == n.phone}
                                      name="agentPhone"
                                      type="radio"
                                      className="me-2"
                                    />
                                    <h6>{n.phone}</h6>
                                  </div>
                                </td>
                                <td>
                                  <h6>$5</h6>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </>
                    :
                    <p className="d-flex align-items-center justify-content-center">
                      <Spinner animation="border" role="status" className="me-2"></Spinner>
                      Please wait, while loading phone numbers...
                    </p>
                }
                <div className="text-center">
                  <Button onClick={() => buyPhone()}>Submit</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Phone