import React, { useState } from 'react';
import { Form, Button, InputGroup, Container, Row, Col } from 'react-bootstrap';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { apiServices } from '../../service/api.service';
import swal from 'sweetalert';

const SignUpStep = ({ setStep, setUser }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim()
      .required('Company name is required'),
    email: Yup.string().trim()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string().trim().required('Phone Number is required')
      .test('valid-phone', 'Phone number is not valid', function (value) {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber && phoneNumber.isValid();
      }),
    password: Yup.string().trim()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
  });
  const [showPassword, setShowPassword] = useState(false);


  return <>
    <section className="contact-us-section" id="contact-us-section">
      <Container>
        <h2 className="main-heading text-center shimmer">Kindly fill the details below <br /> to get started.</h2>
        <Row className="justify-content-center mt-3">
          <Col lg={6} md={8}>
            <div className='get-started-details'>
              <Formik
                enableReinitialize={true}
                initialValues={{ name: "", email: "", phone: "", phoneNumber: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    let created = await apiServices.signUp(values)
                    setUser(created.data.data)
                    localStorage.setItem("safe_tek_admin_token", created.data.data.token)
                    setStep(step => step + 1)
                  } catch (error) {
                    swal({ icon: "warning", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message })
                  }
                }}
              >
                {({ isSubmitting, handleSubmit, values, touched, handleChange, handleBlur, errors, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                      <label htmlFor="name">Company Name</label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.name && !!errors.name}
                        placeholder="Enter company name"
                      />
                      <ErrorMessage name="name" component="div" style={{ color: 'red', fontSize: '12px' }} />
                    </div>

                    {/* Email */}
                    <div className='mb-3'>
                      <label htmlFor="email">Email</label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                        placeholder="Enter email"
                      />
                      <ErrorMessage name="email" component="div" style={{ color: 'red', fontSize: '12px' }} />
                    </div>

                    {/* Phone */}
                    <div className='mb-3'>
                      <label htmlFor="phone">Phone</label>
                      <Field name="phoneNumber">
                        {({ field }) => (
                          <PhoneInput
                            country={'us'}
                            inputClass="w-100"
                            value={values.phoneNumber}
                            onChange={(phone, c, e, value) => {
                              setFieldValue('phone', value)
                              setFieldValue('phoneNumber', phone)
                            }}
                            inputProps={{
                              name: 'phoneNumber',
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="phone" component="div" style={{ color: 'red', fontSize: '12px' }} />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor="password">Password</label>
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                        placeholder="Enter password"
                      />
                      <ErrorMessage name="password" component="div" style={{ color: 'red', fontSize: '12px' }} />
                    </div>

                    <div className='mt-5 text-center'>
                      <Button type="submit" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  </>
};

export default SignUpStep;
