import axios from "axios";

import { apiUrl } from "../config/config";


export const apiServices = {
    contact_us,
    getAvailablePhoneNumbers,
    signUp,
    createAgent,
    buyPhoneNumber,
    updatePhoneNumber
}

function headers() {
    let token = localStorage.getItem("safe_tek_admin_token")
    return { headers: { 'Authorization': `${token}` } }
}

async function contact_us(data) {
    return await axios.post(`${apiUrl}/outgoing/`, data);
}

async function getAvailablePhoneNumbers() {
    return await axios.get(`${apiUrl}/phone-number-list/`, {})
}

async function signUp(params) {
    return await axios.post(`${apiUrl}/signup/`, params, {})
}

async function createAgent(params) {
    return await axios.post(`${apiUrl}/agents/`, params, headers())
}

async function buyPhoneNumber(params) {
    return await axios.post(`${apiUrl}/phone-number-list/`, params, headers())
}

async function updatePhoneNumber(params) {
    return await axios.patch(`${apiUrl}/import-phone-number/`, params, headers())
  }